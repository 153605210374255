<template>
  <div class="image-container">
    <v-img 
      :src="imageUrl ? imageUrl : require('@/assets/not_found.png')"
      :lazy-src="require(
        lazyImage === 'cnic' 
        ? '@/assets/cnic.jpg' 
        : lazyImage === 'face' 
          ? '@/assets/face_placeholder.png' 
          : '@/assets/fingerprint.png')"
      :aspect-ratio="aspectRatio"
      :width="width"
      :height="height"
      class="mb-2"
      :class="styles"
      @click="openImageInNewTab"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="grey lighten-5" />
        </v-row>
      </template>

      <!-- Magnifier Icon -->
      <v-icon
        class="magnifier-icon"
        @click="openImageInNewTab"
      >
        mdi-magnify
      </v-icon>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "imageLazy",
  props: {
    imageUrl: String,
    aspectRatio: String,
    lazyImage: String,
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    styles: {
      type: String,
      default: 'face-style'
    }
  },
  methods: {
    openImageInNewTab() {
      if (this.imageUrl) {
        window.open(this.imageUrl, '_blank');
      } else {
        alert('Image URL is not available.');
      }
    },
  },
}
</script>

<style scoped>
.face-style {
  border-radius: 5%;
  margin: auto;
}
.card-style {
  border-radius: 10px;
}

.magnifier-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 36px;
  color: black;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none; /* Prevent accidental clicks when hidden */
}

.image-container {
  cursor: pointer;
}

.image-container:hover .magnifier-icon {
  opacity: 1; /* Show icon on hover */
  pointer-events: auto; /* Enable clicks on hover */
}

.magnifier-icon:hover {
  transform: translate(-50%, -50%) scale(1.1);
}
</style>